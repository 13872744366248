<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import UserService from '@/services/user'

moment.locale('pt-BR');

export default {
  page: {
    title: "Usuários",
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Usuários",
      loading: false,
      users: [],
      
      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      userSelected: '',

      showModal: false,
      searchEmptyField: false,

      fields: [
        { key: 'attendance', sortable: true, label: 'Origem' },
        { key: 'customerName', sortable: true, label: 'Cliente' },
        { key: 'customerDocumentNumber', sortable: true, label: 'CNPJ' },
        { key: 'customer.group', sortable: true, label: 'Grupo', formatter: (value, key, item) => {
          return item.customer ? (item.customer.group || 'BIKE') : '-'
        } },
        { key: 'name', sortable: true, label: 'Usuário' },
        // { key: 'registerSource', sortable: true, label: 'Origem Cadastro' },
        { key: 'status', sortable: true, label: 'Status' },
        { key: 'phoneNumber', sortable: true, label: 'Telefone' },
        { key: 'city', sortable: true, label: 'Cidade/UF', formatter: (value, key, item) => {
            return `${item.customerCity}/${item.customerState}`;
          }
        },
        { key: 'customerSaleDate', sortable: true, label: 'Última Compra' },
        { key: 'createdAt', sortable: true, label: 'Data Cadastro', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY HH:mm');
        } },
        // { key: 'email', sortable: true, label: 'E-mail' },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    }
  },
  computed: {
    rows() {
      return this.users.length;
    }
  },
  methods: {
    loadUsers(showLoading = true, page = 1, term = '') {
      if (showLoading) {
        this.loading = true;
      }

      UserService.getB2bUsers(page, term)
        .then(res => {
          this.loading = false;

          this.users = res.data.users;
          this.count = res.data.count;
          this.currentCount = res.data.currentCount;
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
          this.perPage = res.data.perPage;
          this.startIndex = res.data.startIndex;
          this.endIndex = res.data.endIndex;
        })
      .catch(() => {
        this.loading = false;
      })
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.loadUsers(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.loadUsers(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.loadUsers(true, 1, this.searchValue);
      }
    },
    confirmRemoveUser(data) {
      if (data.status === 'pending') {
        return this.$toast.warning('Usuário em análise. Não pode ser removido.')
      }

      this.userSelected = data._id;
      this.$bvModal.show('modal-confirm');
    },
    removeUser() {
      this.$bvModal.hide('modal-confirm');

      this.loading = true;

      UserService.removeUser(this.userSelected).then(() => {
        this.loadUsers(false, 1);

        this.loading = false;
        this.$toast.open('Usuário removido')
      }).catch(() => {
        this.loading = false;
      })
    },
    exportData() {
      this.loading = true;

      UserService.exportB2bUsers(this.searchValue).then(res => {
        this.loading = false;
        
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'UsuariosB2B.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        this.loading = false;
      })
    },
  },
  mounted() {
    this.loadUsers();
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <div class="row mb-2 align-items-center">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Nome do usuário ou razão social do cliente..."
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="row mb-2">
              <div class="col-lg-12 mb-2">
                <form @submit.prevent="search" class="align-items-center mb-2">
                  <div class="row">
                    <div class="col-lg-6 col-sm-12">
                      <div class="form-group">
                        <label>Pesquisar Cliente</label>
                        <input
                          type="text"
                          v-model="searchValue"
                          class="form-control"
                          placeholder="Nome do usuário, CNPJ ou razão social do cliente..."
                          v-on:keyup="changeSearch"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="button-search">
                    <b-button type="submit" variant="primary" v-if="!loading">Pesquisar</b-button>
                    <b-button class="ml-2" variant="outline-primary" @click="exportData" v-if="!loading">Exportar</b-button>
                    <a href="javascript:void(0);" class="text-primary" v-if="loading">
                      <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                    </a>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="users && users.length > 0">
              <!-- Table data -->
              <!-- <Customer :users="users" :loadUsers="this.loadUsers" :action="$route.query.action"/> -->

              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="users"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                  <a href="javascript:void(0);" class="action-icon text-danger" @click="confirmRemoveUser(row.item)">
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>

                <template v-slot:cell(customerName)="row">
                  <p class="mb-0">
                    {{row.item.customerName}}
                    <span v-if="row.item.registerSource === 'app'" class="badge badge-pill badge-soft-primary font-size-12 ml-2">Novo</span>
                  </p>
                </template>

                <template v-slot:cell(name)="row">
                  <p class="mb-0">{{row.item.name}}</p>
                  <p class="text-muted mt-0">{{row.item.email}}</p>
                </template>

                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-warning': row.item.status === 'pending',
                      'badge-soft-danger': row.item.status === 'rejected' || row.item.status === 'inactive',
                    }"
                  >{{row.item.statusDescription}}</span>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- <div class="row" v-if="loading">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary">
            <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
          </a>
        </div>
      </div>
    </div> -->

    <b-modal
      id="modal-confirm"
      title="Confirmação"
      title-class="font-18"
      centered
    >
      <p>Tem certeza que deseja desvincular o usuário do cliente? O cadastro com o mesmo CNPJ será permitido após essa ação.</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-confirm')" class="mr-3">Voltar</b-btn>
        <b-btn variant="primary" @click="removeUser">Excluir Usuário</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }
</style>